/* 0. Breakpoints ==================================== */
$bp-tablet: 760px;
$bp-desktop: 1020px;
$bp-large: 1200px;
$bp-xlarge: 1400px;

/* 1. Colors ========================================= */
$primary-color: #ffffff;
$secondary-color: gold;
$accent-color: rgb(234, 180, 2);
$gradient1: #920d8b;
$gradient2: #9b0ed3;
$gradient3: #de67ee;
$grey-color: #9a9a9a;
$fill-color: #000;
$textBLk: #1d1c1e;
$textGreyDrk: #333136;
$textGreyLite: #dfdfdf;

/* 2. Fonts  ========================================= */
$LobFont: 'Lobster Two', cursive;
$RoboFont: 'Roboto', sans-serif;
$BodyFont: 'Montserrat', sans-serif;
$fontMed: 500;
$fontReg: 400;
$fontBold: 700;
$fontLite: 300;

$circ-color: #fd0f07;
$linkedin-color: #00aced;
$instagram_color: #bc39dd;
