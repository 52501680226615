@import "variables";

// medium
@mixin bp-M {
	@media screen and (min-width: $bp-tablet) {
		@content;
	}
}

// large
@mixin bp-L {
	@media screen and (min-width: $bp-desktop) {
		@content;
	}
}
// XLarge
@mixin bp-XL {
	@media screen and (min-width: $bp-large) {
		@content;
	}
}

// XXL
@mixin bp-XXL {
	@media screen and (min-width: $bp-xlarge) {
		@content;
	}
}

// Special Mixins

@mixin bp-Nest {
	@media screen and (min-width: $bp-desktop)and (max-height: 1000px){ 
		@content;
	}
}
