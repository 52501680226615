/* 
==============================================
CONTENT:	Project Name Here
AUTHOR:		Your Name Here
==============================================

TABLE OF CONTENTS:

0. Reset
1. Main Layout
2. Content

==============================================
*/

/* 0. Reset ================================= */

/* 
If you want to add partials, use Sass Modules via @use:
@use 'variables';

Usage: partial-name.variable/mixin/function-name:
background: variables.$primary-color;
*/

@use 'reset';
@import 'variables';
@import 'mixins';

/* 1. Main Layout ================================= */

* {
	box-sizing: border-box;
}

body {
	background: $textBLk;
}

/* 2. Content ================================= */

body {
	font-family: $BodyFont;
}
::-webkit-scrollbar{
	width: 0%;
}
a{
	width: max-content;
}

.anchor{
	position: relative;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(4, 1fr);
	width: 6em;
	height: 6em;

	.ring{
		grid-area: 1/2/2/3;
		border: 5px solid $fill-color;
		border-radius: 100%;
		width: 69%;
		justify-self: center;
	}
	.shaft{
		width: 20%;
		height: 100%;
		background-color: $fill-color;
		display: flex;
		grid-area: 2/2/5/3;
		align-self: center;
		justify-self: center;
	}
	.crossShaft{
		width: 100%;
		height: 20%;
		background: $fill-color;
		grid-area: 2/2/3/3;
		align-self: flex-end;
	}
	.catch{
		border-radius: 100%;
		grid-area: 2/1/5/4;
		border-bottom: 7px solid $fill-color;
		justify-self: center;
		width: 70%;
	}
	.cleft{
		background: $fill-color;
		width: 20%;
		height: 50%;
		justify-self: center;
		align-self: flex-end;
	}
	.cleftL{
		grid-area: 3/1/4/2;
		transform: skewY(45deg);
	}
	.cleftR{
		transform: skewY(-45deg);
		grid-area: 3/3/4/4;
	}

	@include bp-M{
		width: 8em;
		height: 8em;
	}
}
.chainlink{
	margin: 4px 4px;
	border: 2px solid $fill-color;
	border-radius: 100%;
	width: 15px;
	height: 15px;
	position: relative;
	left: 10%;
	transition: rotate 42s ease-in-out;
}

.home_head{
	background: rgb(94, 50, 3);
	height: 10vh;
	border-bottom: .93em solid $accent-color;
	display: grid;
	grid-template-columns: repeat(4, 1fr) 18%;
	grid-template-rows: repeat(1, 1fr);
	z-index: 40;
	position: relative;
	.boarder{
		width: 100%;
		height: 5px;
		position: absolute;
		background: black;
		z-index: 0;
		top: 50%;
	}

	.hide-small{
		display: none;
	}
	
	.logoLink{
		grid-column: 2/5;
		grid-row: 1/3;
		width: 100%;
		position: relative;
		top: -10px;
		z-index: 50;
		
		.head_logo{
			width: inherit;
			display: flex;
			justify-content: center;
			img{
				width: 69%;
			}
		}
	}
	h1{
		grid-column: 2/5;
		grid-row: 1/2;
		color: $primary-color;
		font-size: 2em;
		align-self: center;
		font-family: $LobFont;
		text-transform: capitalize;
		justify-self: center;
		text-shadow: -1px -1px 0 $secondary-color, 1px -1px 0 $secondary-color, -1px 1px 0 $secondary-color, 1px 1px 0 $secondary-color;
	}

	.burg_btn{
		grid-column: 5/6;
		padding: 13px 20%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-self: center;
		height: fit-content;
		cursor: pointer;
		gap: 5px;
		z-index: 10;
		top: -13px;
		position: relative;

		div{
			background: url("../../dist/img/burgerBone.png");
			background-size: 100% 110%;
			background-repeat: no-repeat;
			background-position-y: center;
			width: 100%;
			height: 1.5em;
		}
	}

	#closed{
		.topBun{
			transform: translateY(0px) rotate(0deg);
			transition: transform 420ms ease;
		}
		.botBun{
			transform: translateY(0px) rotate(0deg);
			transition: transform 420ms ease;
		}
	}

	#open{
		padding: 18px 20%;
		gap: 0%;
		.topBun{
			transform: translateY(13px) rotate(-495deg);
			transition: transform 420ms ease;
		}
		.botBun{
			transform: translateY(-13px) rotate(-405deg);
			transition: transform 420ms ease;
		}
	}
	#active{
		display: block;
		position: fixed;
		height: 95vh;
		top: 8%;
		width: 100%;
		background: $textBLk;
		z-index: 2;
		opacity: 0%;
		transition: opacity 420ms ease;

		ul{
			display: flex;
			flex-direction: column;
			height: 80%;
			justify-content: space-between;
			text-align: center;
			padding: 80px;
			position: relative;
			transform: translateX(-100%);
			transition: transform 420ms ease;

			a{
				text-decoration: none;
				color: $primary-color;
				text-shadow: -1px -1px 0 $secondary-color, 1px -1px 0 $secondary-color, -1px 1px 0 $secondary-color, 1px 1px 0 $secondary-color;
				font-family: $LobFont;
				font-size: 4.2em;
				text-transform: capitalize;
			}
		}
	}

	@include bp-M{
		.logoLink .head_logo img{
			width: 50%;
		}
		.burg_btn{
			height: 100%;
			top: 0px;
		}
	}

	@include bp-L{
		.boarder{
			height: 3px;
		}
		.hide-large{
			display: none;
		}
		.hide-small{
			display: block;
			grid-column: 1/6;
			grid-row: 1;
			align-self: center;
			z-index: 1;

			ul{
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 0% 7% 0% 3%;
				li{
					width: max-content;
					a{
						text-decoration: none;
						text-transform: capitalize;
						color: $accent-color;
						font-size: 4.8em;
						font-weight: 900;
						text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
						font-family: $LobFont;
					}
				}
			}
		}
	}
	@include bp-Nest{
		grid-template-columns: repeat(9, 1fr);
		.logoLink{
			grid-column: 4/7;
		}
		.hide-small{
			grid-column: 3/8;

			ul{
				padding: 0% 0%;
				li{
					a{
						font-size: 2.3em;
						font-weight: 900;
					}
				}
			}
		}
	}
}


#fixed{
	position: fixed;
	width: 100%;
	z-index: 40;
	background: #333136;
}


.followTray{
	background: #333136e3;
	border-top: .13em solid #444247e7;
	position: fixed;
	width: 100%;
	height: 6vh;
	bottom: 0%;
	z-index: 40;

	ul{
		display: grid;
		grid-template-columns: minmax(1.3%, 7%) repeat(3, 1fr) minmax(1.3%, 7%);
		height: 100%;
		column-gap: 1.3em;

		li{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 4.2px 0%;

			a{
				height: 100%;
				width: 100%;
				border: .13em solid rgba(255, 217, 0, 0.822);
				border-radius: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;

				figure{
					display: flex;
					justify-content: center;

					i{
						font-size: 1.3em;
						color: $textGreyLite;
						text-decoration: none;

						@include bp-M{
							font-size: 2em;
						}
					}
				}
			}
		}

		.followTray_link1{
			grid-column: 2/3;
		}
		.followTray_link2{
			grid-column: 3/4;

			img{
				width: 30px;
			}
		}
		.followTray_link3{
			grid-column: 4/5;
		}
	}

	@include bp-Nest{
		background: #333136;
		left: 0%;
		width: fit-content;
		height: 100vh;
		display: flex;
		border-top: none;
		border-right: .13em solid #444247e7;
		z-index: 30;
		padding: 13% 0%;

		ul{
			grid-template-columns: 1.3px 1fr 1.3px;
			grid-template-rows: repeat(3, 1fr) minmax(1.3%, 7%);
			height: 33%;
			margin-top: auto;
			row-gap: 1em;
			column-gap: .42em;

			li{
				padding: 0%;
				width: 50px;
				height: 66.6px;
			}

			.followTray_link1{
				grid-area: 1 / 2 / 2 / 3;
			}
			.followTray_link2{
				grid-area: 2 / 2 / 3 / 3;
			}
			.followTray_link3{
				grid-area: 3 / 2 / 4 / 3;
			}
		}
	}
	@include bp-XL{
		background: #333136;
		left: 0%;
		width: fit-content;
		height: 100vh;
		display: flex;
		border-top: none;
		border-right: .13em solid #444247e7;
		z-index: 30;

		ul{
			grid-template-columns: 1.3px 1fr 1.3px;
			grid-template-rows: repeat(3, 1fr) minmax(1.3%, 7%);
			height: 33%;
			margin-top: auto;
			row-gap: 1em;
			column-gap: .42em;

			li{
				padding: 0%;
				width: 50px;
				height: 66.6px;
			}

			.followTray_link1{
				grid-area: 1 / 2 / 2 / 3;
			}
			.followTray_link2{
				grid-area: 2 / 2 / 3 / 3;
			}
			.followTray_link3{
				grid-area: 3 / 2 / 4 / 3;
			}
		}
	}
}

.sign{
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	width: 13em;
	height: 5em;
	align-items: center;
	text-align: center;

	.plank{
		background-color: rgb(165, 77, 42);
		border: 5px solid black;
		width: 100%;
		height: 100%;
		border-radius: 10%;
		display: block;
		z-index: 30;
	}
	article{
		color: $secondary-color;
		text-transform: capitalize;
		font-family: $LobFont;
		padding: 0% 13%;
		font-size: 3.3em;
		position: absolute;
		opacity: 60%;
		z-index: 40;
		justify-content: center;
		text-shadow: -1px -1px 1rem $accent-color 1px -1px 1rem $accent-color -1px 1px 1rem $accent-color 1px 1px 1rem $accent-color;
	}
}

.home_hero{
	display: flex;
	justify-content: center;
	align-content: baseline;
	background-image: url("../../dist/img/heroGIF.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;

	.video_foreground{
		position: fixed;
		z-index: -1;
		top: 0%;
		background: rgba(0, 0, 0, 1);
		opacity: 42%;
		transition: opacity 850ms ease;
		width: 100%;
		height: 100%;
	}
	
	height: 100vh;

	.hero_container{
		display: grid;
		grid-template-rows: repeat(7, 1fr);
		grid-template-columns: repeat(5, 1fr);

		.text_bubble{
			grid-area: 4/3/7/6;
			display: grid;
    		grid-template-rows: repeat(3, 1fr);
    		grid-template-columns: repeat(4, 1fr);
			transition: all 13ms ease-in-out;
			z-index: 20;

			.hero_head-corner{
				grid-row: 1 / 2;
				align-self: center;
				grid-column: 1 / 2;
				transform: rotateZ(45deg);
				width: 25px;
				height: 25px;
				background: $primary-color;
				border-bottom: 0.42em $grey-color solid;
				border-left: 0.24em $grey-color solid;
				position: relative;
				left: -10px;
				z-index: 20;
			}
	
			.hero_head{
				background: $primary-color;
				color: $fill-color;
				height: fit-content;
				padding: 15px 10%;
				border-radius: 42px;
				border-top: 0.13em $grey-color solid;
				border-bottom: 0.42em $grey-color solid;
				border-left: 0.13em $grey-color solid;
				border-right: 0.42em $grey-color solid;
				grid-row: 1 / 4;
    			grid-column: 1 / 5;
    			width: 100%;
    			margin-left: 10px;
				position: relative;
				left: -10px;
		
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				// text-shadow: -1px -1px 0 $textBLk, 1px -1px 0 $textBLk, -1px 1px 0 $textBLk, 1px 1px 0 $textBLk;
		
				article{
					z-index: 10;
					font-family: $RoboFont;
					font-size: 1em;
				}
		
				h2{
					font-family: $RoboFont;
					font-size: 2em;
				}
				h3{
					font-size: 3em;
					font-family: $LobFont;
					text-transform: capitalize;
		
					@include bp-M{
						font-size: 4em;
					}
				}
		
				p{
					line-height: 1.5;
					font-size: 1.3em;
					margin: 20px 0%;
					font-weight: 700;
		
					@include bp-M{
						font-size: 2.5em;
					}
				}
				
			}
		
			.hide-small{
				display: none;
			}

		}

		@include bp-M{
			grid-template-rows: repeat(13, 1fr);
    		grid-template-columns: repeat(9, 1fr);

			.text_bubble{
				grid-area: 7/5/12/9;
				font-size: .69em;
			}
		}
		@include bp-L{
			.text_bubble{
				grid-area: 7/5/12/9;
				font-size: 1em;
			}
		}
		@include bp-Nest{
			grid-template-columns: repeat(10, 1fr);
			.text_bubble{
				grid-area: 7/6/12/10;
				font-size: .5em;
			}
		}
	}


}

.leafPNG{
	position: relative;
	img{
		width: 2em;
		position: absolute;
		z-index: 10;
	}
}

.home_main{
	text-align: center;
	// padding: 30px 10%;
	margin: 0% 0%;
	background: linear-gradient(-45deg, $textGreyDrk, $textBLk, $textGreyDrk, $textBLk);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	border-radius: 10px;
	overflow: hidden;
	margin-top: -13vh;

	.border_container{
		overflow: hidden;
		width: 100vw;
		height: 20em;
		position: absolute;
		z-index: 10;
		display: flex;
		align-items: center;
		margin-top: -10em;
		@include bp-Nest{
			margin-top: -11em;
		}
	}

	.main_border{
		width: 100%;
		height: 1.6em;
		background: rgb(107, 66, 28);
		border: 3px solid rgb(68, 42, 18);
		border-bottom: 5px solid rgb(68, 42, 18);
		display: flex;
		transform: rotateZ(2deg);
		box-shadow: 0px 10px 5em 3em black;

		.branch1{
			background: rgb(107, 66, 28);
			border-left: 3px solid rgb(68, 42, 18);
			border-right: 3px solid rgb(68, 42, 18);
			width: 1em;
			height: 3em;
			transform: rotateZ(45deg);
			position: relative;
			top: -2.4em;
			left: 13%;

			.leafPNG{
				top: -1.8em;
				left: -2.15em;
				transform-origin: 2.5em 2em;
				transform: rotateZ(15deg);
				animation: windyLeaf1 13s 3800ms ease-in-out infinite;
			}
			.branch11{
				background: rgb(107, 66, 28);
				border-left: 3px solid rgb(68, 42, 18);
				border-right: 3px solid rgb(68, 42, 18);
				width: 1em;
				height: 3em;
				transform: rotateZ(45deg);
				position: relative;
				top: -70%;
				left: 120%;

				.leafPNG{
					top: -1.6em;
					left: -2.15em;
					transform-origin: 2.5em 2em;
					transform: rotateZ(15deg);
					animation: windyLeaf2 13s 3600ms ease-in-out infinite;
				}
			}
		}
		.branch2{
			background: rgb(107, 66, 28);
			border-left: 3px solid rgb(68, 42, 18);
			border-right: 3px solid rgb(68, 42, 18);
			width: 1em;
			height: 3em;
			transform: rotateZ(-25deg);
			position: relative;
			top: .8em;
			left: .1%;

			.leafPNG{
				top: 1em;
				left: -2.2em;
				transform-origin: 2.5em 2em;
				transform: rotateZ(-60deg);
				animation: windyLeaf3 13s 4200ms ease-in-out infinite;
			}
			.branch21{
				background: rgb(107, 66, 28);
				border-left: 3px solid rgb(68, 42, 18);
				border-right: 3px solid rgb(68, 42, 18);
				width: 1em;
				height: 2.5em;
				transform: rotateZ(-45deg);
				position: relative;
				top: 80%;
				left: 110%;

				.leafPNG{
					top: -.1em;
					left: -2.1em;
					transform-origin: 2.5em 2em;
					transform: rotateZ(120deg);
					animation: windyLeaf4 13s 4000ms ease-in-out infinite;
				}
			}
		}
		.branch3{
			background: rgb(107, 66, 28);
			border-left: 3px solid rgb(68, 42, 18);
			border-right: 3px solid rgb(68, 42, 18);
			width: 1em;
			height: 3em;
			transform: rotateZ(-60deg);
			position: relative;
			top: .25em;
			left: 35%;

			.leafPNG{
				top: .5em;
				left: -2em;
				transform-origin: 2.5em 2em;
				transform: rotateZ(-30deg);
				animation: windyLeaf5 13s 2800ms ease-in-out infinite;
			}
			.branch31{
				background: rgb(107, 66, 28);
				border-left: 3px solid rgb(68, 42, 18);
				border-right: 3px solid rgb(68, 42, 18);
				width: 1em;
				height: 3em;
				transform: rotateZ(45deg);
				position: relative;
			}
		}
		.branch4{
			background: rgb(107, 66, 28);
			border-left: 3px solid rgb(68, 42, 18);
			border-right: 3px solid rgb(68, 42, 18);
			width: 1em;
			height: 3em;
			transform: rotateZ(60deg);
			position: relative;
			top: -2em;
			left: 50%;

			.leafPNG{
				top: -1.3em;
				left: -2.2em;
				transform-origin: 2.5em 2em;
				transform: rotateZ(-60deg);
				animation: windyLeaf6 13s 2200ms ease-in-out infinite;
			}
			.branch41{
				background: rgb(107, 66, 28);
				border-left: 3px solid rgb(68, 42, 18);
				border-right: 3px solid rgb(68, 42, 18);
				width: 1em;
				height: 3em;
				transform: rotateZ(-30deg);
				position: relative;
				top: -70%;
				left: -150%;
				z-index: 20;

				.leafPNG{
					top: -1.7em;
					left: -1.7em;
					transform-origin: 2.5em 2em;
					transform: rotateZ(15deg);
					animation: windyLeaf7 13s 2000ms ease-in-out infinite;
				}
			}
		}
		.branch5{
			background: rgb(107, 66, 28);
			border-left: 3px solid rgb(68, 42, 18);
			border-right: 3px solid rgb(68, 42, 18);
			width: 1em;
			height: 3em;
			transform: rotateZ(-45deg);
			position: relative;
			top: .25em;
			left: 69%;

			.leafPNG{
				top: 1em;
				left: -2em;
				transform-origin: 2.5em 2em;
				transform: rotateZ(-45deg);
				animation: windyLeaf8 13s 1200ms ease-in-out infinite;
			}
			.branch51{
				background: rgb(107, 66, 28);
				border-left: 3px solid rgb(68, 42, 18);
				border-right: 3px solid rgb(68, 42, 18);
				width: 1em;
				height: 3em;
				transform: rotateZ(-45deg);
				position: relative;
				top: 75%;
				left: 120%;

				.leafPNG{
					top: .5em;
					left: -2em;
					transform-origin: 2.5em 2em;
					transform: rotateZ(-30deg);
					animation: windyLeaf9 13s 500ms ease-in-out infinite;
				}
			}
		}
		.branch6{
			background: rgb(107, 66, 28);
			border-left: 3px solid rgb(68, 42, 18);
			border-right: 3px solid rgb(68, 42, 18);
			width: 1em;
			height: 3em;
			transform: rotateZ(45deg);
			position: relative;
			top: -2.4em;
			left: 75%;

			.leafPNG{
				top: .8em;
				left: -2.2em;
				transform-origin: 2.5em 2em;
				transform: rotateZ(-30deg);
				animation: windyLeaf10 13s 1000ms ease-in-out infinite;
			}
			.branch61{
				background: rgb(107, 66, 28);
				border-left: 3px solid rgb(68, 42, 18);
				border-right: 3px solid rgb(68, 42, 18);
				width: 1em;
				height: 3em;
				transform: rotateZ(45deg);
				position: relative;
				top: -75%;
				left: 125%;

				.leafPNG{
					top: -1.8em;
					left: -2.2em;
					transform-origin: 2.5em 2em;
					transform: rotateZ(-60deg);
					animation: windyLeaf11 13s ease-in-out infinite;
				}
				.branch611{
					background: rgb(107, 66, 28);
					border-left: 3px solid rgb(68, 42, 18);
					border-right: 3px solid rgb(68, 42, 18);
					width: 1em;
					height: 5em;
					transform: rotateZ(-30deg);
					position: relative;
					top: -145%;
					left: -225%;
				}
			}
		}

	}
	

	.project_sign-container{
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 5% 0%;

		.sign{
			transform: rotateZ(-5deg);
			animation: signFloat 6660ms ease-in-out infinite;
			transition: translate 4200ms ease-in-out;

			.boardM{
				display: none;
			}
			.boardL{
				display: none;
			}
		}
		@include bp-M{
			.sign{
				height: 7em;
				width: 42%;
				article{
					width: 100%;
					font-size: 4.2em;

					p{
						text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
					}
				}
			}
		}
	}

	.main_bkgd{
		position: absolute;
		width: 100vw;
		height: 275vh;
		background: url("../../dist/img/tumblr_89a85fac4e9e203ebc0393e93a3e33be_95c1386f_500.gif");
		background-size: cover;
		background-position: 40%;
		background-repeat: no-repeat;
		filter: blur(4px);
		box-shadow: inset 1px 1px 10em 4em $fill-color;

		.main_bkgd-blur{
			width: 100%;
			height: 100%;
			background: linear-gradient(rgba(0, 157, 255, 0.756), pink, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25), rgba(12, 9, 10, 0.769));
			opacity: 90%;
			display: grid;
			grid-template-columns: repeat(120, 1fr);
			grid-template-rows: repeat(360, 1fr);
		}
	}
	.main_bkgd1{
		position: absolute;
		width: 100vw;
		height: 130vh;
		background: url("../../dist/img/upsidedown.gif");
		background-size: cover;
		background-position: 40%;
		background-repeat: no-repeat;
		box-shadow: inset 0 -3em 4em $fill-color;
		filter: blur(4px);

		.main_bkgd-blur{
			width: 100%;
			height: 100%;
			background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.8));
			opacity: 90%;
			display: grid;
			grid-template-columns: repeat(120, 1fr);
			grid-template-rows: repeat(360, 1fr);
		}

	}


	h3{
		text-transform: capitalize;
		font-size: 2em;
		margin: 30px 0% 80px 0%;
		color: $primary-color;
		font-family: $LobFont;
		transition: text-shadow 420ms ease-in-out;
		animation: textShadowPulse 4200ms infinite;

		@include bp-M{
			font-size: 3.5em;
		}
		@include bp-Nest{
			font-size: 2.3em;
		}
	}

	ul{
		height: 100%;
		border-radius: 10%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: 4em;
		position: relative;

		.card_front-bkgd{
			height: 100%;
			width: 100%;
		}

		.project_mm{
			animation: cardFloat 6660ms ease-in-out 1000ms infinite;
			transition: translate 4200ms ease-in-out;

			.card_front{
				.card_front-bkgd{
					background: linear-gradient(-42deg, black, rgb(237, 141, 82), rgba(0, 200, 255, 0.562), pink, gold);
					animation: tarotBKGD 13s ease-in-out infinite;
				}
				.card_front-img{
					background-image: url("../../dist/img/movingMando.png");
				background-repeat: no-repeat;
				background-size: cover;
				}
			}
		}
		.project_dd{
			animation: cardFloat 6660ms ease-in-out 2000ms infinite;
			transition: translate 4200ms ease-in-out;
			.card_front{
				.card_front-bkgd{
					background: linear-gradient(42deg, blue, skyblue, pink, gold);
					animation: tarotBKGD 13s ease-in-out infinite;
				}
				.card_front-img{
					background-image: url("../../dist/img/captainsPort.png");
					background-repeat: no-repeat;
					background-size: cover;
				}
			}
		}
		.project_zz{
			animation: cardFloat 6660ms ease-in-out 3000ms infinite;
			transition: translate 4200ms ease-in-out;
			.card_front{
				
				.card_front-bkgd{
					background: linear-gradient(42deg, black, purple, black);
					animation: tarotBKGD 13s ease-in-out infinite;
				}
				.card_front-img{
					background-image: url("../../dist/img/buildBlog.png");
					background-repeat: no-repeat;
					background-size: cover;
				}
			}
		}

		.project_card-container{
  			perspective: 500px;

			.project_card{
				height: 25em;
				background: rgb(247, 227, 201);
				border: .2em solid black;
				border-radius: 5%;
				width: 14em;
				transition: transform 1s;
				transform-style: preserve-3d;

			}
			.card_front{
				display: grid;
				grid-template-columns: repeat(12, 1fr);
				grid-template-rows: repeat(11, 1fr);
				width: 100%;
				height: 100%;
				position: absolute;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				transform: rotateX(0deg);
				// padding: 6% 6% 2% 6%;

				.card_front-bkgd{
					grid-area: 2/2/11/12;
					position: relative;
					background-size: 400px 400px;
					left: 1px;
					z-index: 0;

					@include bp-M{
						background-size: 542px 542px;
					}
					@include bp-L{
						background-size: 700px 700px;
					}
				}
				.card_front-img{
					position: relative;
					z-index: 10;
					grid-area: 1/1/12/13;
					width: 100%;
					height: 100%;
				}
				h3{
					margin: 0% 0%;
					animation: none;
					color: $fill-color;
					text-shadow: -1px -1px 0 $accent-color, 1px -1px 0 $accent-color, -1px 1px 0 $accent-color, 1px 1px 0 $accent-color;
				}
			}
			.card_back{
				display: flex;
				transform: rotateY(180deg);
				flex-direction: column;
				padding: 6% 6% 2% 6%;
				color: $textBLk;
				height: 100%;
				justify-content: space-evenly;
				position: absolute;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				background: rgb(247, 227, 201);


				.project_card-descContainer{
					border: 7px solid $fill-color;
					border-radius: 13%;
					padding: 3%;
					margin-bottom: 2%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					height: 100%;

					p{
						font-size: 1.1em;
						letter-spacing: 1px;
						line-height: 20px;
						font-size: large;
						color: $textBLk;
					}
					a{
						color: $textBLk;
						text-decoration: none;
						font-size: .9em;
						width: 100%;
						display: flex;
						justify-content: center;
						cursor: pointer;
					}
				}
				h3{
					margin: 0% 0%;
					animation: none;
					color: $fill-color;
					text-shadow: -1px -1px 0 $accent-color, 1px -1px 0 $accent-color, -1px 1px 0 $accent-color, 1px 1px 0 $accent-color;
					margin-bottom: 13px;
				}
			}
		}
		

		@include bp-M{
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			padding: 0% 2%;
			gap: 2em;

			.project_card-container{
				
				.project_card{
					width: 22em;
					height: 40em;
					overflow: hidden;

					.card_back .project_card-descContainer p{
						font-size: 1.9em;
						line-height: 32px;
					}
					.card_back .project_card-descContainer a{
						font-size: 1.5em;
					}
				}
			}
		}
		@include bp-L{
			.project_card-container{
				.project_card{
					width: 28em;
					height: 48em;
					overflow: visible;

					.card_back .project_card-descContainer p{
						font-size: 1.9em;
						line-height: 32px;
					}
					.card_back .project_card-descContainer a{
						font-size: 1.5em;
					}
				}
				.card_front{
					grid-template-columns: repeat(13, 1fr);
					.card_front-bkgd{
						grid-area: 2/2/11/12;
						width: 105%;
						position: relative;
						top: 1%;
					}
					.card_front-img{
						grid-area: 1/1/12/14

					}
				}
			}
		}
		@include bp-Nest{
			padding: 0% 5% 0% 13%;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, 1fr);


			.project_card-container{

				.project_card{
					width: 15em;
					height: 25em;

					
					.card_back .project_card-descContainer p{
						font-size: 1em;
						line-height: 25px;
					}
					.card_back .project_card-descContainer a{
						font-size: .8em;
					}
				}
			}
		}
		@include bp-L{
			.project_card-container:hover .project_card{
				transform: rotateY( 180deg ) ;
  				transition: transform 0.5s;
			}
		}
	}
	.seabed{
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(12, 1fr);
		padding: 0%;
		gap: 0%;

		.skillBouy{
			width: 3.5em;
			height: 3.5em;
			background: linear-gradient(130deg, $gradient1 50%, $textGreyDrk 50%);
			border: 3px $fill-color solid;
			display: flex;
			flex-direction: column;
			align-items: center;
			z-index: 20;
			p{
				transform: rotateZ(130deg);
				position: relative;
				top: 25%;
				font-weight: 900;
				font-size: 1.3em;
				color: $primary-color;
				width: fit-content;
				text-transform: capitalize;
				z-index: 30;
			}
		}
		@include bp-M{
			position: relative;
			top: 2%;
			left: 5%;

			.skillBouy{
				width: 5em;
				height: 5em;
				p{
					font-size: 2em;
				}
			}
		}
		@include bp-Nest{
			left: 9%;

			.skillBouy{
				width: 3.6em;
				height: 3.6em;
				p{
					font-size: 1.5em;
				}
			}
		}
	}
	.main_pros{
		margin-top: 10%;
		height: 130vh;

		.itemLS{
			display: grid;
			height: 100%;
			width: 100%;
			position: relative;
			left: 2%;
			grid-area: 6/2/13/3;
			grid-template-rows: repeat(5, 1fr);

			.chainHead{
				transform: rotateZ(-123deg);
				margin-top: -10px;
			}
			.anchor{
				grid-row: 5/6;
				transform: rotateZ(-8deg);
			}
		}
		.itemMU{
			display: grid;
			height: 100%;
			width: 100%;
			position: relative;
			left: -49%;
			top: 7%;
			grid-area: 5/2/12/3;
			grid-template-rows: repeat(5, 1fr);

			.chainHead{
				transform: rotateZ(-119deg);
				margin-top: -10px;
			}
			.anchor{
				grid-row: 5/6;
				transform: rotateZ(-13deg);
			}
		}
		.itemFW{
			display: grid;
			height: 100%;
			width: 100%;
			position: relative;
			left: -1%;
			grid-area: 6/1/13/2;
			grid-template-rows: repeat(5, 1fr);

			.chainHead{
				transform: rotateZ(-118deg);
				margin-top: -10px;
			}
			.anchor{
				grid-row: 5/6;
				transform: rotateZ(-15deg);
			}
		}
		.itemDB{
			display: grid;
			height: 100%;
			width: 100%;
			grid-area: 5/3/12/4;
			position: relative;
			left: -33%;
			top: 10%;
			grid-template-rows: repeat(5, 1fr);

			.chainHead{
				transform: rotateZ(-130deg);
				margin-top: -10px;
			}
			.anchor{
				grid-row: 5/6;
				transform: rotateZ(-5deg);
			}
		}
		.itemGE{
			display: grid;
			height: 100%;
			width: 100%;
			grid-area: 6/3/13/4;
			position: relative;
			left: 40%;
			grid-template-rows: repeat(5, 1fr);

			.chainHead{
				transform: rotateZ(-90deg);
				margin-top: -10px;
			}
			.anchor{
				grid-row: 5/6;
				transform: rotateZ(-42deg);
			}
		}
	}
}

.home_footer{
	background: $textBLk;
	display: flex;
	flex-direction: column;
	padding: 20px 10%;
	box-shadow: 0px -10px 42em 7em black;
	position: relative;
	z-index: 30;
	

	.home_footer-content{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(1, 1fr);
		color: $primary-color;
		
		h5{
			font-size: 1.6em;
			font-family: $RoboFont;
			text-transform: capitalize;
			margin: 20px 0%;
		}

		.footer_content-grats{
			display: none;
		}
		.resources{
			grid-area: 1 / 1 / 3 / 2;
		}
		.quickLinks{
			grid-area: 1 / 2 / 3 / 4;
			text-align: right;
			ul{
				align-items: end;
			}
		}
		ul{
			display: flex;
			flex-direction: column;
			line-height: 1.5;

			a{
				text-decoration: none;
				color: $textGreyLite;
				text-transform: capitalize;
			}
		}

		@include bp-M{
			h5{
				font-size: 3em;
			}
			p{
				font-size: 2em;
			}
			a{
				font-size: 1.6em;
			}
		}

	}
	.dividerLine{
		width: 100%;
		height: 2px;
		background: $textGreyLite;
		margin: 20px 0%;
	}
	.home_footer-rights{
		text-align: center;
		color: $textGreyLite;
		font-size: .69em;
		margin-bottom: 60px;
	}
	@include bp-M{
		height: 24em;
	}
	@include bp-L{
		height: 25.5em;
		.home_footer-content{
			padding: 0% 7%;

		}
		.home_footer-content ul{
			padding: 0% 13%;
		}
	}
	@include bp-Nest{
		height: 23em;
	}
}

.card .is-flipped {
	transform: rotateY(180deg);
  }

@keyframes windyLeaf1{
	0%{
		transform: rotateZ(-5deg);
	}
	20%{
		transform: rotateZ(-5deg);
	}
	40%{
		transform: rotateZ(-30deg);
	}
	60%{
		transform: rotateZ(-5deg);
	}
	80%{
		transform: rotateZ(-25deg);
	}
	100%{
		transform: rotateZ(-5deg);
	}
}
@keyframes windyLeaf2{
	0%{
		transform: rotateZ(-5deg);
	}
	20%{
		transform: rotateZ(-5deg);
	}
	40%{
		transform: rotateZ(-30deg);
	}
	60%{
		transform: rotateZ(-5deg);
	}
	80%{
		transform: rotateZ(-25deg);
	}
	100%{
		transform: rotateZ(-5deg);
	}
}
@keyframes windyLeaf3{
	0%{
		transform: rotateZ(-60deg);
	}
	20%{
		transform: rotateZ(-60deg);
	}
	40%{
		transform: rotateZ(-30deg);
	}
	60%{
		transform: rotateZ(-60deg);
	}
	80%{
		transform: rotateZ(-45deg);
	}
	100%{
		transform: rotateZ(-60deg);
	}
}
@keyframes windyLeaf4{
	0%{
		transform: rotateZ(120deg);
	}
	20%{
		transform: rotateZ(120deg);
	}
	40%{
		transform: rotateZ(100deg);
	}
	60%{
		transform: rotateZ(120deg);
	}
	80%{
		transform: rotateZ(105deg);
	}
	100%{
		transform: rotateZ(120deg);
	}
}
@keyframes windyLeaf5{
	0%{
		transform: rotateZ(-30deg);
	}
	20%{
		transform: rotateZ(-30deg);
	}
	40%{
		transform: rotateZ(-0deg);
	}
	60%{
		transform: rotateZ(-30deg);
	}
	80%{
		transform: rotateZ(-5deg);
	}
	100%{
		transform: rotateZ(-30deg);
	}
}
@keyframes windyLeaf6{
	0%{
		transform: rotateZ(-60deg);
	}
	20%{
		transform: rotateZ(-60deg);
	}
	40%{
		transform: rotateZ(-90deg);
	}
	60%{
		transform: rotateZ(-60deg);
	}
	80%{
		transform: rotateZ(-85deg);
	}
	100%{
		transform: rotateZ(-60deg);
	}
}
@keyframes windyLeaf7{
	0%{
		transform: rotateZ(15deg);
	}
	20%{
		transform: rotateZ(15deg);
	}
	40%{
		transform: rotateZ(-15deg);
	}
	60%{
		transform: rotateZ(15deg);
	}
	80%{
		transform: rotateZ(-05deg);
	}
	100%{
		transform: rotateZ(15deg);
	}
}
@keyframes windyLeaf8{
	0%{
		transform: rotateZ(-45deg);
	}
	20%{
		transform: rotateZ(-45deg);
	}
	40%{
		transform: rotateZ(-15deg);
	}
	60%{
		transform: rotateZ(-45deg);
	}
	80%{
		transform: rotateZ(-20deg);
	}
	100%{
		transform: rotateZ(-45deg);
	}
}
@keyframes windyLeaf9{
	0%{
		transform: rotateZ(-30deg);
	}
	20%{
		transform: rotateZ(-30deg);
	}
	40%{
		transform: rotateZ(-0deg);
	}
	60%{
		transform: rotateZ(-30deg);
	}
	80%{
		transform: rotateZ(-5deg);
	}
	100%{
		transform: rotateZ(-30deg);
	}
}
@keyframes windyLeaf10{
	0%{
		transform: rotateZ(-30deg);
	}
	20%{
		transform: rotateZ(-30deg);
	}
	40%{
		transform: rotateZ(-60deg);
	}
	60%{
		transform: rotateZ(-30deg);
	}
	80%{
		transform: rotateZ(-55deg);
	}
	100%{
		transform: rotateZ(-30deg);
	}
}
@keyframes windyLeaf11{
	0%{
		transform: rotateZ(-60deg);
	}
	20%{
		transform: rotateZ(-60deg);
	}
	40%{
		transform: rotateZ(-90deg);
	}
	60%{
		transform: rotateZ(-60deg);
	}
	80%{
		transform: rotateZ(-85deg);
	}
	100%{
		transform: rotateZ(-60deg);
	}
}

@keyframes swaying{
	0%{
		transform: rotateZ(0deg);
	}
	10%{
		transform: rotateZ(-5deg);
	}
	20%{
		transform: rotateZ(0deg);
	}
	30%{
		transform: rotateZ(5deg);
	}
	40%{
		transform: rotateZ(0deg);
	}
	50%{
		transform: rotateZ(0deg);
	}
	60%{
		transform: rotateZ(0deg);
	}
	70%{
		transform: rotateZ(-5deg);
	}
	80%{
		transform: rotateZ(0deg);
	}
	90%{
		transform: rotateZ(5deg);
	}
	100%{
		transform: rotateZ(0deg);
	}
}


@keyframes signFloat{
	0%{
		transform: translateY(13px) rotateZ(-3deg);
	}
	50%{
		transform: translateY(0px) rotateZ(-5deg);
	}
	100%{
		transform: translateY(13px) rotateZ(-3deg);
	}
}
@keyframes cardFloat{
	0%{
		transform: translateY(13px) rotateZ(1deg);
	}
	50%{
		transform: translateY(0px) rotateZ(-3deg);
	}
	100%{
		transform: translateY(13px) rotateZ(1deg);
	}
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes textShadowPulse {
	0%{
		text-shadow: -1px -1px 0 $secondary-color, 1px -1px 0 $secondary-color, -1px 1px 0 $secondary-color, 1px 1px 0 $secondary-color;
	}
	50%{
		text-shadow: -1px -1px 1.3rem $secondary-color, 1px -1px 1.3rem $secondary-color, -1px 1px 1.3rem $secondary-color, 1px 1px 1.3rem $secondary-color;
	}
	100%{
		text-shadow: -1px -1px 0 $secondary-color, 1px -1px 0 $secondary-color, -1px 1px 0 $secondary-color, 1px 1px 0 $secondary-color;
	}
}
@keyframes tarotBKGD {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes cardFlip{
	0%{
		transform: rotateY(0deg);
		transition: transform 1300ms;
	}
	50%{
		transform: rotateY(90deg);
		transition: transform 1300ms;
	}
	100%{
		transform: rotateY(180deg);
		transition: transform 1300ms;
	}
}